import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@material-ui/core'
import {
  SubmitHandler,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Form, SelectForm, TextFieldForm } from 'shared/forms'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import { OrganizationAutocomplete } from 'modules/organization/components/OrganizationAutcomplete'
import { BankAccountAutocomplete } from 'modules/bank-account/components/BankAccountAutocomplete'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'
import { DatePickerForm } from 'shared/forms/DatePickerForm'
import { useRegisterAccountTransferMutation } from 'modules/account/data/Mutations'
import { Account } from 'modules/account/models/Account'

interface CreateForm {
  source: { account: Account }
  sourceType: string
  target: { account: Account }
  targetType: string
  amount: number
  date: dayjs.Dayjs
  notes?: string
}

const schema = yup.object().shape({
  source: yup.object().nullable().required('Debe seleccionar la cuenta origen'),
  sourceType: yup
    .string()
    .isOptional('none')
    .required('Debe seleccionar el tipo cuenta origen'),
  target: yup
    .object()
    .nullable()
    .required('Debe seleccionar la cuenta destino'),
  targetType: yup
    .string()
    .isOptional('none')
    .required('Debe seleccionar el tipo cuenta destino'),
  amount: yup
    .number()
    .nullable()
    .moreThan(0, 'Debe ingresar un monto mayor')
    .required('El monto del ingreso es requerido'),
  notes: yup.string().isOptional(),
  date: yup
    .object()
    .nullable()
    .test('test-date', 'La fecha debe ser mayor al 2021', function (value) {
      const minDate = dayjs('01-01-2021', 'DD-MM-YYYY')
      const ddate = dayjs(new Date(value.toString()))
      return !ddate.isBefore(minDate)
    })
    .required('La fecha del ingreso es requerida'),
})

const dialogId = 'transfers/create-dialog'
const formId = 'transfers/create-form'

export const Create = () => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [addTransfer, { loading }] = useRegisterAccountTransferMutation()

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<CreateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      source: null,
      target: null,
      amount: null,
      date: dayjs(),
      notes: '',
      sourceType: 'none',
      targetType: 'none',
    },
  })

  const { formState } = formInstance

  const { isDirty } = formState

  const onSubmit: SubmitHandler<CreateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const { source, target, amount, date, notes } = values
      const dateOnly = date.format('YYYY-MM-DD')

      await addTransfer({
        variables: {
          data: {
            date: date.toDate(),
            dateOnly,
            sourceId: source.account.id,
            targetId: target.account.id,
            amount,
            notes,
          },
        },
      })

      enqueueSnackbar('Transferencia realizada correctamente', {
        variant: 'success',
      })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        'Ha ocurrido un error al realizar la transferencia. Intentelo más tarde',
        { variant: 'error' },
      )
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/transfers')
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEnforceFocus
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Transferencia entre cuentas</DialogTitle>
      <DialogContent>
        <Form formProps={{ id: formId }} onSubmit={onSubmit} {...formInstance}>
          <SelectForm
            name="sourceType"
            label="Tipo de cuenta origen"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            disabled={loading}
          >
            <MenuItem value="none">
              <em>Seleccionar tipo de cuenta</em>
            </MenuItem>
            <MenuItem value="organization">Organización</MenuItem>
            <MenuItem value="bankAccount">Cuenta de banco</MenuItem>
          </SelectForm>
          <AccountAutocomplete
            label="Cuenta origen"
            placeholder="¿Buscar cuenta?"
            name="source"
            typeField="sourceType"
          ></AccountAutocomplete>
          <SelectForm
            name="targetType"
            label="Tipo de cuenta destino"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            disabled={loading}
          >
            <MenuItem value="none">
              <em>Seleccionar tipo de cuenta</em>
            </MenuItem>
            <MenuItem value="organization">Organización</MenuItem>
            <MenuItem value="bankAccount">Cuenta de banco</MenuItem>
          </SelectForm>
          <AccountAutocomplete
            label="Cuenta origen"
            placeholder="¿Buscar cuenta?"
            name="target"
            typeField="targetType"
          ></AccountAutocomplete>
          <DatePickerForm
            name="date"
            label="Fecha de la transferencia"
            inputProps={{
              variant: 'outlined',
              className: 'mb-16',
              required: true,
              fullWidth: true,
            }}
            minDate={dayjs('01-01-2021', 'DD-MM-YYYY')}
            maxDate={dayjs()}
          ></DatePickerForm>
          <TextFieldForm
            TextComponent={CurrencyTextField}
            name="amount"
            label="Monto"
            placeholder="Ingresar monto del ingreso"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            disabled={loading}
          />
          <TextFieldForm
            name="notes"
            label="Notas"
            placeholder="Ingresar notas"
            variant="outlined"
            className="mb-16"
            rows={3}
            multiline
            fullWidth
            disabled={loading}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface AccountAutocompleteProps {
  name: string
  typeField: string
  label: string
  placeholder: string
}

const AccountAutocomplete = ({
  name,
  typeField,
  label,
  placeholder,
}: AccountAutocompleteProps) => {
  const { getValues, control } = useFormContext()

  const accountType = useWatch<string>({
    control,
    name: typeField,
    defaultValue: getValues(typeField),
  })

  if (accountType === 'organization') {
    return (
      <OrganizationAutocomplete
        name={name}
        inputProps={{ label, variant: 'outlined' }}
        placeholder={placeholder}
        className="mb-16"
        fullWidth
      ></OrganizationAutocomplete>
    )
  }

  if (accountType === 'bankAccount') {
    return (
      <BankAccountAutocomplete
        name={name}
        inputProps={{ label, variant: 'outlined' }}
        placeholder={placeholder}
        className="mb-16"
        fullWidth
      ></BankAccountAutocomplete>
    )
  }

  return null
}
