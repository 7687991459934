import { gql, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client'
import { Income, PaymentMethod } from '../models/Income'

export interface ListIncomeResponse {
  listIncome: Income[]
  listIncomeTotals: {
    paymentMethod: PaymentMethod | null
    amount: number
  }[]
}

export interface ListIncomePayload {
  organizationId?: string
  employeeId?: string
  initDate: Date
  endDate: Date
}

export const ListIncomeFragment = gql`
  fragment ListIncomeFragment on Income {
    id
    date
    dateOnly
    notes
    amount
    company {
      id
      name
    }
    employee {
      id
      name
    }
    category {
      id
      name
    }
    organization {
      id
      name
    }
    bankAccount {
      id
      name
      accountNumber
    }
    customerCredit {
      id
      name
    }
    invoice {
      id
      code
    }
    ticket {
      id
      code
    }
    paymentMethod
    status
    createdAt
    tenantId
  }
`

const LIST_INCOME = gql`
  query ListIncome($data: ListIncomePayload!) {
    listIncomeTotals(data: $data) {
      paymentMethod
      amount
    }
    listIncome(data: $data) {
      ...ListIncomeFragment
    }
  }
  ${ListIncomeFragment}
`

export const useIncomeList = (
  options?: QueryHookOptions<ListIncomeResponse, { data: ListIncomePayload }>,
) => {
  const result = useLazyQuery<ListIncomeResponse, { data: ListIncomePayload }>(
    LIST_INCOME,
    options,
  )
  return result
}

const GET_INCOME = gql`
  query GetIncome($id: String!) {
    getIncome(id: $id) {
      ...ListIncomeFragment
    }
  }
  ${ListIncomeFragment}
`
export type GetIncomeResponse = { getIncome: Income }
export interface GetIncomeVars {
  id: string
}

export const useGetIncome = (
  options?: QueryHookOptions<GetIncomeResponse, GetIncomeVars>,
) => {
  return useQuery<GetIncomeResponse, GetIncomeVars>(GET_INCOME, options)
}
