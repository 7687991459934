import { useMutation, gql } from '@apollo/client'
import { AccountTransfer } from '../models/AccountTransfer'

export interface RegisterAccountTransferPayload {
  sourceId: string
  targetId: string
  date: Date
  /** YYYY-MM-DD string */
  dateOnly?: string
  amount: number
  notes?: string
}

const REGISTER_TRANSFER = gql`
  mutation CreateAccountTransfer($data: RegisterAccountTransferPayload!) {
    registerTransfer(data: $data) {
      id
    }
  }
`

export const useRegisterAccountTransferMutation = () => {
  const tuple = useMutation<
    { registerAccountTransfer: { id: string } },
    { data: RegisterAccountTransferPayload }
  >(REGISTER_TRANSFER)

  return tuple
}

const DELETE_TRANSFER = gql`
  mutation DeleteAccountTransfer($id: String!) {
    deleteTransfer(id: $id) {
      id
      status
    }
  }
`

export const useDeleteAccountTransferMutation = () => {
  const tuple = useMutation<
    { deleteTransfer: AccountTransfer },
    { id: string }
  >(DELETE_TRANSFER)

  return tuple
}
